import { createRouter, createWebHistory } from "vue-router";

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import("../views/Home.vue")
	},
	{
		path: "/:slug",
		name: "Page",
		component: () => import("../views/Page.vue")
	},
	{
		path: "/stories",
		name: "Stories Grid",
		component: () => import("../views/StoriesGrid.vue")
	},
	{
		path: "/stories/:slug",
		name: "Stories",
		component: () => import("../views/Stories.vue")
	},
	{
		path: "/blog",
		name: "Blog",
		component: () => import("../views/Blog.vue")
	},
	{
		path: "/blog/page/:page",
		name: "BlogPagination",
		component: () => import("../views/Blog.vue"),
		props: true
	},
	{
		path: "/blog/:slug",
		name: "SingleBlog",
		component: () => import("../views/SingleBlog.vue")
	},
	{
		path: "/:pathMatch(.*)*",
		name: "NotFound",
		component: () => import("../views/PageNotFound.vue")
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		window.scrollTo(0, 0);
	}
});

export default router;
